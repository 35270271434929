import React from 'react';
import {
  Added,
  Fixed,
  Improved,
  Link,
  PageWithSubNav,
  Paragraph,
  PlatformHeader,
  Removed,
} from '../../../../components';
import styles from '../../index.module.scss';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="March 2022" subnav="release-notes">
      <div id="March2022" className={styles.release}>
        <Paragraph className="uni-margin--one--vertical">
          March was a busy month for the Uniform squad! We finally helped
          integrate the new{' '}
          <Link href="/components/modules/design">Module components</Link> into
          the Hudl Beta experience and still had time for all of these updates
          and improvements. Check out the new icons, documentation, Figma
          improvements and more below.
        </Paragraph>
        <div className={styles.releaseInfo}>
          <div className={styles.platform}>
            <PlatformHeader libName="Docs" />
            <div className={styles.changesList}>
              <Improved>
                API documentation for{' '}
                <Link href="/components/modules/code">Module components</Link>.
              </Improved>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="Components"
              version="4.16.1"
              versionUrl="https://github.com/hudl/uniform-ui-components/releases"
            />
            <div className={styles.changesList}>
              <Added>
                New{' '}
                <Link href="/components/icons/technology/design">
                  Battery Icon set
                </Link>{' '}
                to better show battery level and status.
              </Added>
              <Improved>
                <Link href="/components/modules/data-card/code?web#headerTitle">
                  DataCard header title
                </Link>{' '}
                now supports React nodes.
              </Improved>
              <Fixed>
                <Link href="/components/modules/segmented-bar/design">
                  Mini Segmented Bar visualization
                </Link>{' '}
                showing percentages/counts when it shouldn't.
              </Fixed>
              <Fixed>
                <Link href="/components/modules/segmented-bar/design">
                  Segmented Bar visualization
                </Link>{' '}
                properly displays levels with similar percentages.
              </Fixed>
              <Fixed>
                <Link href="/components/modules/histogram/design">
                  Histogram visualization
                </Link>{' '}
                properly displays bar widths based on the number of bars.
              </Fixed>
              <Removed>
                <code>hasError</code> prop of{' '}
                <Link href="/components/forms/search-input/code">
                  Search Input
                </Link>{' '}
                has been deprecated.
              </Removed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader
              libName="React Native"
              version="4.3.0 (Planned for April 15th)"
              versionUrl="https://github.com/hudl/jarvis/releases"
            />
            <div className={styles.changesList}>
              <Added>
                New{' '}
                <Link href="/components/icons/technology/design">
                  Battery Icon set
                </Link>{' '}
                to better show battery level and status.
              </Added>
              <Added>
                The <code>labelsToDisplay</code> prop to the{' '}
                <Link href="/components/modules/histogram/code?reactnative#labelsToDisplay">
                  Histogram
                </Link>
                .
              </Added>
              <Improved>
                Selected state for{' '}
                <Link href="/components/modules/histogram/design">
                  Histogram
                </Link>{' '}
                and{' '}
                <Link href="/components/modules/segmented-bar/design">
                  Segmented Bar
                </Link>{' '}
                items to include the confirm icon and label.
              </Improved>
              <Fixed>
                <Link href="/components/modules/histogram/design">
                  Histogram visualization
                </Link>{' '}
                properly displays bar widths based on the number of bars.
              </Fixed>
              <Removed>
                <code>hasError</code> prop of{' '}
                <Link href="/components/forms/search-input/code/?reactnative">
                  Search Input
                </Link>{' '}
                has been deprecated.
              </Removed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Figma Library" />
            <div className={styles.changesList}>
              <Added>
                Built-in interaction for the{' '}
                <Link href="/components/buttons/button/design/">
                  mobile Button
                </Link>{' '}
                <code>status</code> property.
              </Added>
              <Added>
                Interactive states for the{' '}
                <Link href="/components/forms/select/design/">Select</Link>,{' '}
                <Link href="/components/forms/input/design/">Input</Link>, and{' '}
                <Link href="/components/forms/text-area/design/">Select</Link>{' '}
                web components.
              </Added>
              <Improved>
                The performance of the{' '}
                <Link href="/components/buttons/button/design/">
                  mobile Button
                </Link>
                .
              </Improved>
              <Improved>
                Swapping between Android and iOS variants for the{' '}
                <Link href="/components/buttons/button/design/">
                  mobile Button
                </Link>
                .
              </Improved>
              <Removed>
                The <code>hasError</code> variants for the{' '}
                <Link href="/components/forms/search-input/code/">
                  Search Input
                </Link>{' '}
                in both web and mobile.
              </Removed>
            </div>
          </div>
          <div className={styles.platform}>
            <PlatformHeader libName="Storybook" />
            <div className={styles.changesList}>
              <Added>
                Empty Sandbox environment and{' '}
                <Link href="https://github.com/hudl/uniform-ui-components/blob/main/CONTRIBUTING.md#storybook-sandbox">
                  documentation
                </Link>
                .
              </Added>
            </div>
          </div>
        </div>
      </div>
    </PageWithSubNav>
  );
};

export default IndexPage;
